import clsx from 'clsx'
import { forwardRef } from 'react'
import { TestProps } from 'typ'
import { Color, Orientation, Spacing, wrapperCx } from './Separator.css'

export interface SeparatorProps extends TestProps {
  spacing: Spacing
  orientation: Orientation
  color?: Color
  className?: string
}

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>(
  ({ spacing, orientation, color = 'neutral', className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      aria-hidden={true}
      role="separator"
      className={clsx([wrapperCx({ spacing, orientation, color }), className])}
      data-orientation={orientation}
      data-spacing={spacing}
      data-color={color}
    />
  )
)

Separator.displayName = 'Separator'
